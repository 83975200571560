// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/providers.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/providers.tsx");
  import.meta.hot.lastModified = "1739185044119.9387";
}
// REMIX HMR END

// app/providers.tsx
"use client";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { useTheme } from "next-themes";
export function Providers({
  children
}) {
  _s();
  const {
    theme
  } = useTheme();
  return <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme={"light"}>
        {children}
      </NextThemesProvider>
    </NextUIProvider>;
}
_s(Providers, "JkSxfi8+JQlqgIgDOc3wQN+nVIw=", false, function () {
  return [useTheme];
});
_c = Providers;
var _c;
$RefreshReg$(_c, "Providers");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;